
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';


@Component({
    components: {
        
    },
})
export default class LevelOne extends Vue {
    @Prop() value! : any;
    steps= []
    pBarSize= ''
    currentStep= 1 

    mounted() {
            if (!this.value || this.value.length == 0) {
              return;
            }
            this.steps = this.value.map((s, i) => ({ number: i+1, selected: false, ...s}) );
            this.steps[0].selected = true;
            this.$nextTick(() => {
              this.calculateBarPosition();
            });
           window.addEventListener('resize', this.calculateBarPosition);
    }

    @Watch('value')
    x(){
            if (!this.value || this.value.length == 0) {
              return;
            }
            this.steps = this.value.map((s, i) => ({ number: i+1, selected: false, ...s}) );
            this.steps[0].selected = true;
            this.$nextTick(() => {
              this.calculateBarPosition();
            });
           window.addEventListener('resize', this.calculateBarPosition);
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.calculateBarPosition);
    }
  get progress() {
      if (this.currentStep > this.steps.length) {
        return `width: 100%`;
      }
      const first = document.getElementById('step-1');
      const current = document.getElementById(`step-${this.currentStep}`);
      if (first && current) {
        const delta = current.getBoundingClientRect().right -first.getBoundingClientRect().right;
        return `width: ${delta}px;`;
      }
  }
    moveStep(stepNumber) {
      this.currentStep = stepNumber; 
    }
    calculateBarPosition() {
      let docEl = document.documentElement;
      const first = document.getElementById('step-1');
      let rect = first.getBoundingClientRect();
      const offset = rect.left + (window.pageXOffset || docEl.scrollLeft || 0);
      const top = rect.top + rect.height/2 - 2;
      this.pBarSize = `left: ${offset}px; right: ${offset}px; top: ${top}px`;
    }
    finalize() {
      this.currentStep += 1;
    }

    finish(){
       this.$emit('changeComponent', this.currentStep)
    }
}
